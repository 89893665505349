import React, { useState } from 'react';

interface TextInputProps {
    value: string;
    onChange: (value: string) => void;

    id?: string;
    title?: string;
    description?: string;
    errorMessage?: string;
    disabled?: boolean;
    placeholder?: string;
    required?: boolean;
    maxLength?: number;

    className?: string;
    titleClassName?: string;
    inputClassName?: string;
    containerClassName?: string;
    descriptionClassName?: string;
    onBlur?: () => void;
    onFocus?: () => void;
}

export const TextInput = ({
    value, 
    onChange,

    id,
    title,
    description,
    errorMessage,
    disabled = false,
    placeholder = 'placeholder',
    required = false,
    maxLength,
    
    className,
    titleClassName,
    containerClassName,
    descriptionClassName='text-gray-600', 
    inputClassName,
    onBlur,
    onFocus,

}: TextInputProps) => {
    const [touched, setTouched] = useState(false);

    const showError = required && touched && !value;
    const currentLength = value ? value.toString().length : 0;
  
    const borderColor = showError ? 'border-red-500' : 'border-gray-300 hover:border-blue-500';
    const backgroundColor = showError ? 'bg-red-100' : 'bg-white';


    // const minHeightClass = `min-h-[${minHeight.toString()}px]`;
    // console.log('this is min ehight class')
    // console.log(minHeightClass)

    return (
        <div className={`flex flex-col gap-1 ${containerClassName}`}>
            <div className='flex flex-col gap-1'>
                {title && (
                    <p 
                        className={`
                          ml-2 text-gray-500 text-[16px] font-medium leading-4
                          ${titleClassName}
                        `}
                    >
                        {title}{required && <span className='text-red-500'>*</span>}
                    </p>
                )}
                {description && (
                    <p className={`ml-2 text-sm ${descriptionClassName}`}>
                        {description}
                    </p>
                )}
            </div>

            <textarea
                id={id ? id : title ? title : undefined}
                name={title}
                placeholder={value ? placeholder : placeholder}
                
                value={value ? value : ''}
                onChange={(e) => onChange(e.target.value)}
                disabled={disabled}
                onBlur={() => {
                    setTouched(true);
                    onBlur && onBlur();
                }}
                onFocus={onFocus}
                maxLength={maxLength}
                
                className={`
                    border ${borderColor} ${backgroundColor}
                    text-gray-900
                    placeholder-gray-400
                    py-3 px-4 focus:bg-white
                    resize-none
                    rounded-xl 
                    ${inputClassName}
                    ${disabled ? 'text-gray-400' : 'text-gray-900'}
                `}
            />

            <div className='flex flex-row'>
                <p
                    className={`text-xs ml-2 text-red-500 border-1 ${showError ? '' : 'hidden'}`}
                >
                    {showError ? 'This field is required' : errorMessage}
                </p>
                {maxLength && (
                    <p className="ml-2 text-xs text-gray-500 ml-auto">
                        {currentLength}/{maxLength}
                    </p>
                )}
            </div>
        </div>
    )
}
