// components/SupportWidget.tsx
import React, {useEffect, useState, ChangeEvent, useRef} from "react";
import { createPortal } from 'react-dom';

import axios from 'axios';
import { useRouter } from "next/router";
import { BiMessageSquareDetail } from "react-icons/bi";
import { IoClose } from "react-icons/io5";
import { Button } from "@/src/atoms-new/Button";
import { ModalContainer } from "@/src/components/containers/modal-container/modalContainer";
import { TextInput } from "@/src/form-new/input/TextInput";
import { useAppSelector } from "@/src/hooks/useSelector";
import html2canvas from 'html2canvas';

import useOutsideClick from '@/src/hooks/useOutsideClick2'

import { fileToBase64 } from '@/src/helpers/image';
import { v4 as uuidv4 } from 'uuid';

export const UserFeedbackWidget: React.FC = () => {
    const router = useRouter();
    const userData = useAppSelector(state => state.user).data
    

    //state : none, menu, form
    type WidgetState = 'icon' | 'menu'
    const [widgetState, setWidgetState] = useState<WidgetState>('icon')

    //type: bug, feature, feedback
    type FeedbackType = 'bug' | 'feature' | 'feedback' | 'hidden' | 'submitted'
    const [feedbackType, setFeedbackType] = useState<FeedbackType>('hidden')
    const [modalTitle, setModalTitle] = useState('Send Feedback')
    useEffect(() => {
        if (feedbackType=='bug') { setModalTitle('Report a Bug') }
        else if (feedbackType=='feature') { setModalTitle('Request a Feature') }
        else { setModalTitle('Send Feedback') }
    },[feedbackType])

    // FORM BUTTON LOADING STATE
    const [loading, setLoading] = useState(false)

    // FORM DATA
    const [input, setInput] = useState('')
    const [file, setFile] = useState<File | Blob | null>(null)
    //reset on close
    useEffect(() => {
        setInput('')
        setFile(null)
    },[feedbackType])


    /** TAKE SCREENSHOT */
    const hideRef = useRef<HTMLDivElement>(null)
    const captureScreenshot = async () => {
        // if (hideRef.current) {
        //     console.log("hididng this element")
        //     hideRef.current.style.display = 'none';
        // }
        if (hideRef.current) {
            const fixedChildren = hideRef.current.querySelectorAll('*');
            fixedChildren.forEach((child) => {
                (child as HTMLElement).style.display = 'none';
            });
        }


        const element = document.body; // Change to the specific element you want to capture
        const canvas = await html2canvas(element);
    
        // Convert canvas to a data URL (base64 image)
        const image = canvas.toDataURL('image/png');
        // Restore the element after screenshot
        // if (hideRef.current) { hideRef.current.style.display = ''; }
        if (hideRef.current) {
            const fixedChildren = hideRef.current.querySelectorAll('*');
            fixedChildren.forEach((child) => {
                (child as HTMLElement).style.display = '';
            });
        }

        // Optionally, upload the screenshot as a file
        const blob = await (await fetch(image)).blob();
        const file = new File([blob], 'screenshot.png', { type: 'image/png' });

        const convertedFile = await fileToBase64(file);
        const folder = 'contact__feedback__screenshot'
        // id: user OR none w/ uuid
        let id = `guest:${uuidv4().split('-')[4]}`
        if (userData && userData.user) { id = userData.user.id }

        //submit image to D3 SPACES
        const response = await fetch('/api/images/upload', {
            method: 'POST',
            headers: {'Content-Type': 'application/json',},
            body: JSON.stringify({ file:convertedFile, folder:folder, id:id }),
        });
        const data = await response.json();
        const {url} = data
        if (url) { return url } 
        else return ''
      };


    /** UPLOAD FILE FROM USER */
    const uploadFileHandler = async (e:ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const selectedFile = e.target.files?.[0] || null;
        if (selectedFile) {
            if (selectedFile.type==='image/heic') {
                //convert heic image
                const heic2any = require('heic2any');
                const convertedBlob = await heic2any({
                    blob: selectedFile,
                    toType: 'image/jpeg',
                });
                setFile(convertedBlob)
            } else {
                setFile(selectedFile)
            }
        } else {
            setFile(null)
        }
    }


    /** SUBMIT FORM */
    const submitHandler = async(e:React.FormEvent) => {
        e.preventDefault();
        setLoading(true)

        //get backend url
        const res = await fetch('/api/env/backend-url')
        const {backendUrl} = await res.json();
        if (!backendUrl) {
            console.error('Backend URL env is missing');
            setLoading(false)
            return 
        }

        //set config 
        let config:any = { headers: {'Content-Type': 'Application/json',}}
        if (userData && userData.user && userData.user.token) {
            const token = userData.user.token
            config = { headers: {
                'Content-Type': 'Application/json',
                Authorization: `Bearer ${token}`
            }}
        } 

        //take screenshot & upload to D3 spaces & get url
        const screenshot = await captureScreenshot()
        console.log('this is screenshot result!')
        console.log(screenshot)

        // gather submission data
        let submitData:any = {
            type:feedbackType,
            feedback: input,
            pathname: router.asPath,
            screenshot_png: screenshot
        }   
        //if file exists, upload & add to submitData         
        if (file) {
            const convertedFile = await fileToBase64(file as File);
            const folder = 'contact__feedback'
            // id: user OR none w/ uuid
            let id = `guest:${uuidv4().split('-')[4]}`
            if (userData && userData.user) { id = userData.user.id }

            //submit image to D3 SPACES
            const response = await fetch('/api/images/upload', {
                method: 'POST',
                headers: {'Content-Type': 'application/json',},
                body: JSON.stringify({ file:convertedFile, folder:folder, id:id }),
            });
            const data = await response.json();
            const {url} = data
            if (url) { submitData.submitted_file = url}
        }

        // submit to backend
        const response = await axios.post(
            `${backendUrl}/api/contact/submit-user-feedback/`,
            submitData,
            config
        )
        const {status, data} = response

        if (status==201) {
            // on success
            setInput('')
            setFile(null)
            setFeedbackType('submitted')
        } else {
            console.error('theres been an error sending feedback')
        }
        setLoading(false)
    }


    //modal container 
    const ref = useRef(null);
    useOutsideClick(ref, () => {setFeedbackType('hidden')})
    
    useEffect(() => {
        if (feedbackType!='hidden') {
            document.body.classList.add('overflow-hidden');
        } else {
            document.body.classList.remove('overflow-hidden');
        }
    
        return () => {
            // Clean up when the component unmounts
            document.body.classList.remove('overflow-hidden');
        };
    }, [feedbackType]);


    return (
        <>
            {   widgetState=='icon' ? (
                <div 
                    onClick={() => setWidgetState('menu')}
                    className={`fixed bottom-24 right-4 bg-blue-600 text-white p-3 rounded-full shadow-lg cursor-pointer hover:bg-blue-700 z-[10]
                        ${widgetState=='icon' ? 'opacity-100' : 'opacity-0'}   
                `}>
                    <BiMessageSquareDetail size={24}/>
                </div>
            ) : (
                <ul className={`
                    fixed bottom-16 right-4 w-fit
                    z-10 bg-white divide-y divide-gray-100 rounded-lg shadow text-gray-700 
                    ${widgetState=='menu' ? 'opacity-100' : 'opacity-0'}
                `}>
                    <li
                        className='cursor-pointer'
                        onClick={() => {
                            setFeedbackType('bug');
                            setWidgetState('icon')
                        }}
                    >
                        <p 
                            className={`
                                text-sm block px-4 py-2 hover:bg-gray-100 
                            `}>Report a bug
                        </p>
                    </li>
                    <li
                        className='cursor-pointer'
                        onClick={() => {
                            setFeedbackType('feature');
                            setWidgetState('icon')
                        }}
                    >
                        <p 
                            className={`
                                text-sm block px-4 py-2 hover:bg-gray-100 
                            `}>
                                Request a Feature
                        </p>
                    </li>
                    <li
                        className='cursor-pointer'
                        onClick={() => {
                            setFeedbackType('feedback');
                            setWidgetState('icon')
                        }}
                    >
                        <p 
                            className={`
                                text-sm block px-4 py-2 hover:bg-gray-100
                            `}>Send Feedback
                        </p>
                    </li>
                    <li 
                        className='cursor-pointer'
                        onClick={() => setWidgetState('icon')}
                    >
                        <p 
                            className={`
                                text-sm block px-4 py-2 hover:bg-gray-100
                            `}>Cancel
                        </p>
                    </li>
                </ul>
            )}


            {/** ACTIVE MODAL */}
            
            {   createPortal(
                <>
                    <div // Overlay div
                        // tabIndex = {-1}         //for modal screen
                        aria-hidden="true"    //remove children from accessing tree
                        className={`fixed flex items-center justify-center bg-black bg-opacity-50 z-[100] w-full h-full overflow-hidden inset-0
                            transition-transform duration-500 ease-in-out
                            ${feedbackType!='hidden' ? '': 'hidden'}
                        `}
                    ></div>
                    
                    <div  // Main Modal -- Overlay div
                        // tabIndex = {-1}         //for modal screen
                        // aria-hidden = "true"    //remove children from accessing tree
                        ref = {hideRef}
                        className = {`
                            fixed flex items-center justify-center z-[101] w-full h-full overflow-x-hidden overflow-y-auto inset-0
                            transition-transform duration-300 ease-in-out
                            ${feedbackType!='hidden'  ? 
                                'translate-y-0 opacity-100'
                                : 'translate-y-full opacity-0'}
                    `}> 
                        {/**   Wrapper Div for the Modal Screen */}
                        <div
                            ref = {ref}
                            className={`
                                relative
                                max-h-full max-w-full
                                min-h-[10rem]
                                ml-auto mr-auto bg-white rounded-md
                            `}
                        >
                            {/**  Modal Content Div -- contains title & close button */}
                            <div className={`w-[90dvw] sm:w-fit h-[70vh] sm:h-fit px-8 py-4 relative`}>
                                <div 
                                    onClick={() => setFeedbackType('hidden')}
                                    className='absolute top-2 right-2 p-2'
                                >
                                    <IoClose size={24}/>
                                </div>
                                {   feedbackType!='submitted' ? (
                                <>
                                    <form 
                                        onSubmit={submitHandler}
                                        className='flex flex-col gap-6 w-full h-full items-center'>
                                        <h3>{modalTitle}</h3>
                                        <div className=' w-full sm:w-[400px] h-full sm:h-[400px]'>
                                            <TextInput 
                                                value={input}
                                                onChange={(val) => setInput(val)}
                                                placeholder='Send us your feedback'
                                                containerClassName='h-full'
                                                inputClassName='h-full'
                                            />
                                        </div>
                                        <input
                                            type="file"
                                            accept="image/*, image/heic"
                                            onChange={uploadFileHandler}
                                            className=" text-sm text-gray-500 "
                                        />
                                        <Button
                                            color='bg-blue-600 hover:bg-blue-700'
                                            textColor='text-white'
                                            borderColor=''
                                            isSubmit={true}
                                            rounded='md' size='md'
                                            className='w-full'
                                            disabled={!input ? true : false}
                                            loading={loading}
                                            >Submit
                                        </Button>
                                    </form>
                                </>
                                ) : (
                                    <div 
                                        className='flex flex-col gap-6 w-full h-full items-center'
                                    >
                                        <h3>Submission Success</h3>
                                        <p>Thank you for submission and helping make Ambition better!</p>
                                        <Button
                                            color='bg-blue-600 hover:bg-blue-700'
                                            textColor='text-white'
                                            borderColor=''
                                            rounded='md' size='md'
                                            className='w-full'
                                            onClick={() => setFeedbackType('hidden')}
                                            >Close
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </>, document.body
            )}
        </>
    );
};
