
// convert File object to Image
export async function createImageFromFile(file: File): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        URL.revokeObjectURL(img.src); // Clean up the object URL after the image is loaded
        resolve(img);
      };
      img.onerror = (error) => {
        reject(new Error('Failed to create image from file'));
      };
      img.src = URL.createObjectURL(file);
    });
  }


  export const fileToBase64 = async (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      
      reader.onloadend = () => {
        // The result will be a data URL, which includes the base64 encoding
        const base64String = reader.result as string;
        
        // Remove the data URL prefix
        const base64Data = base64String.replace(/^data:.+;base64,/, '');
        
        resolve(`data:${file.type};base64,${base64Data}`);
      };
      
      reader.onerror = () => reject(new Error('Failed to read file'));
      
      // Read the file as a data URL
      reader.readAsDataURL(file);
    });
  };

